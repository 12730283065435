import { useFetchGetMenu, useFetchMandatesById } from './fetch-data'

export const useFetchInitMenu = () => {
    const FetchGetMenu = useFetchGetMenu()

    const { permissions } = FetchGetMenu
    const infoUSer = permissions[0]
    const idUser = infoUSer?.idUser

    const { mandates } = useFetchMandatesById(idUser)

    const infoMandatos = mandates[0]
    const profile = infoMandatos?.profile
    const currentDate = infoMandatos?.currentDate


    return {
        FetchGetMenu,
        mandates,
        idUser,
        profile,
        currentDate
    }
}
