import React, { useState, Fragment } from 'react'
import logo from '../../../assets/images/endless-logo-dark.png'
import UserMenu from './userMenu'
import { Link } from 'react-router-dom'
import { MoreHorizontal, Maximize, AlignJustify, AlignLeft } from 'react-feather'
// import cerrrarSesion from '../../../assets/images/cerrar-sesion.svg'
import { descriptions } from '../../../constant/global'
import RelojColombia from '../../common/horaActual'

// import { useKeycloak } from '@react-keycloak/web'

const Header = () => {
    // const { keycloak } = useKeycloak()
    const [sidebar, setSidebar] = useState(false)
    const [headerbar, setHeaderbar] = useState(true)
    // const [time, setTime] = useState(new Date())

    // const _currentDate = new Date()
    // _currentDate.setHours(_currentDate.getHours() + 1)
    // const currentDate = _currentDate.toISOString()
    // // const currentDate = new Date().toISOString()
    
    // ============= esto fue lo que se quitó ============
    // const _expiredDate = new Date(localStorage.getItem('exp_session') * 1000)
    // _expiredDate.setHours(_expiredDate.getHours() + 1)
    // const expiredDate = _expiredDate.toISOString()


    const profile = localStorage.getItem('profile')

    const openCloseSidebar = () => {
        if (sidebar) {
            setSidebar(!sidebar)
            document.querySelector('.page-main-header').classList?.remove('open')
            document.querySelector('.page-sidebar').classList?.remove('open')
        } else {
            setSidebar(!sidebar)
            document.querySelector('.page-main-header').classList?.add('open')
            document.querySelector('.page-sidebar').classList?.add('open')
        }
    }

    function goFull() {
        if ((document.fullScreenElement && document.fullScreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen()
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen()
            } else if (document.documentElement.webkitRequestFullScreen) {
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen()
            }
        }
    }

    // let closeSession = () => {
    //     sessionStorage.removeItem('token')
    //     window.location.href = `/test-react/login`
    // }

    window.onresize = function () {
        // if (screen.width < 992) {
        //     setSidebar(true)
        //     document.querySelector('.page-main-header').classList?.add('open')
        //     document.querySelector('.page-sidebar').classList?.add('open')
        // } else {
        //     setSidebar(false)
        //     document.querySelector('.page-main-header').classList?.remove('open')
        //     document.querySelector('.page-sidebar').classList?.remove('open')
        // }
    }

    // useEffect(() => {
    //     setTimeout(() => {
    //         if (expiredDate < currentDate && expiredDate && currentDate && localStorage.getItem('token')) {
    //             // localStorage.clear()
    //             // keycloak.logout()
    //         }
    //     }, 5000)
    // }, [time])

    return (
        <Fragment>
            <div className='page-main-header header-ccto'>
                <div className='main-header-right row logo-ccto'>
                    <div className='mobile-sidebar d-block icon-visible-ccto'>
                        {/* style={{ 'border-radius': '4px', background: '#142534' }} */}
                        {/* #193958 */}
                        <div className='media-body text-right switch-sm'>
                            <label className='switch'>
                                {/* <a href='#javascript' onClick={() => openCloseSidebar()}> */}
                                <span aria-hidden='true' onClick={() => openCloseSidebar()}>
                                    {/* <AlignLeft /> */}
                                    {!sidebar ? <AlignJustify /> : <AlignLeft />}
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className='main-header-left d-lg-none'>
                        <div className='logo-wrapper'>
                            <Link to={`/inicio`}>
                                <img
                                    className='ml-3'
                                    src={logo}
                                    // style={{ width: '150px', height: '53.75px' }}
                                    alt=''
                                />
                            </Link>
                        </div>
                    </div>
                    <div className='nav-right col p-0 nav-ccto'>
                        <ul className={`nav-menus ${headerbar ? '' : 'open'}`} style={{ background: '#f4f4f4' }}>
                            <li className='text-muted menu-detail-ccto text-left'>
                                {/* <span className='text-muted'>
                                    Expiración de la sesión <strong> {expiredDate.substring(11, 16)} </strong>
                                </span> */}
                                <RelojColombia />
                            </li>
                            <li className='text-muted menu-detail-ccto text-left col-2'>
                                <strong>{descriptions[profile] || 'Desconocido'}</strong>
                            </li>
                            <li className='text-muted menu-detail-ccto'>
                                <UserMenu />
                            </li>
                            {headerbar ? (
                                ''
                            ) : (
                                <>
                                    {/* <li className='close-session-ccto'>
                                        <img
                                            id='LogOut'
                                            className='img-fluid cursor cerrar-sesion'
                                            src={cerrrarSesion}
                                            alt='logo'
                                            onClick={closeSession}
                                            aria-hidden='true'
                                        />
                                    </li> */}
                                    <li className='maximize-session-ccto'>
                                        <a onClick={goFull} className='text-dark' href='#!'>
                                            <Maximize className='text-warning' id='max' />
                                        </a>
                                    </li>
                                </>
                            )}
                        </ul>
                        <div className='d-lg-none mobile-toggle pull-right' onClick={() => setHeaderbar(!headerbar)} aria-hidden='true'>
                            <MoreHorizontal className='text-warning' />
                        </div>
                    </div>
                    {/* <UncontrolledTooltip placement='bottom' target='max'>
                        Maximizar
                    </UncontrolledTooltip> */}
                    {/* <UncontrolledTooltip placement='bottom' target='LogOut'>
                        Cerrar sesión
                    </UncontrolledTooltip> */}
                </div>
            </div>
        </Fragment>
    )
}
export default Header
