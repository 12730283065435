import React, { Fragment, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
// ---- para el logo del sidebar desktop --------
import logo from '../../../assets/images/endless-logo.png'
import logo_compact from '../../../assets/images/logo-ccto-portada.png'

import { MENU_DEFAULT } from '../../../constant/menu'
import { Link } from 'react-router-dom'
import { translate } from 'react-switch-lang'
import data from '../../../data/customizer/config'

import { useFetchInitMenu } from '../../../hooks/menu'

const Sidebar = props => {
    const abortController = new AbortController()
    const [margin, setMargin] = useState(0)
    const [width, setWidth] = useState(0)
    const [hideLeftArrowRTL, setHideLeftArrowRTL] = useState(true)
    const [hideRightArrowRTL, setHideRightArrowRTL] = useState(true)
    const [hideRightArrow, setHideRightArrow] = useState(true)
    const [hideLeftArrow, setHideLeftArrow] = useState(true)
    const [mainmenu, setMainMenu] = useState([])
    const [MENUITEMS, setMENUITEMS] = useState([]) // COMMENT FOR SHOW ALL MENU
    // const MENUITEMS = MENU_DEFAULT  // AND UINCOMMENT FOR SHOW ALL MENU
    const { settings } = data
    const { sidebar } = settings
    const { wrapper } = sidebar

    const layout = useSelector(content => content.Customizer.layout)

    const { FetchGetMenu } = useFetchInitMenu()
    const { permissions, loadingPermissions } = FetchGetMenu
    // console.log('permissions',permissions)
    useEffect(() => {
        // ============================== COMMENT FOR DISABLE PERMISSIONS FOR USER =====================================================
        if (permissions.length === 0 && !loadingPermissions)
            setMENUITEMS([
                {
                    title: '¡Sin permisos!',
                    icon: 'Home',
                    path: '/inicio',
                    type: 'link',
                    active: true
                }
            ])
        if (permissions.length > 0) setMENUITEMS([])

        for (let index = 0; index < MENU_DEFAULT.length; index++) {
            const _defaultPermission = MENU_DEFAULT[index]

            if (_defaultPermission.children) {
                let _makeChildren = { ..._defaultPermission, children: [] }

                for (let index = 0; index < permissions.length; index++) {
                    const _permissions_research = permissions[index]

                    const foundChildren = _defaultPermission.children.find(element => element.code === _permissions_research.code)

                    foundChildren && _makeChildren.children.push(foundChildren)
                }
                _makeChildren.children.length !== 0 && setMENUITEMS(MENUITEMS => [...MENUITEMS, _makeChildren])
            } else {
                const found = permissions.find(element => element.code === _defaultPermission.code)
                found && setMENUITEMS(MENUITEMS => [...MENUITEMS, _defaultPermission])
            }
        }
        // ============================== COMMENT FOR DISABLE PERMISSIONS FOR USER =====================================================

        window.addEventListener('resize', handleResize)
        handleResize()

        // var currentUrl = window.location.pathname

        // mainmenu.filter(items => {
        //     if (items.path === currentUrl) setNavActive(items)
        //     if (!items.children) return false
        //     // eslint-disable-next-line
        //     items.children.filter(subItems => {
        //         if (subItems.path === currentUrl) setNavActive(subItems)
        //         if (!subItems.children) return false
        //         // eslint-disable-next-line
        //         subItems.children.filter(subSubItems => {
        //             if (subSubItems.path === currentUrl) setNavActive(subSubItems)
        //         })
        //     })
        // })

        setTimeout(() => {
            const elmnt = document.getElementById('myDIV')
            const menuWidth = elmnt?.offsetWidth || ''
            // setMenuWidth(menuWidth)
            if (menuWidth > window.innerWidth) {
                setHideRightArrow(false)
                setHideLeftArrowRTL(false)
            } else {
                setHideRightArrow(true)
                setHideLeftArrowRTL(true)
            }
        }, 500)

        return () => {
            window.addEventListener('resize', handleResize)
            abortController.abort()
        }
        // eslint-disable-next-line
    }, [permissions]) // permissions

    const handleResize = () => {
        setWidth(window.innerWidth - 310)
    }

    // const setNavActive = item => {
    //     // eslint-disable-next-line
    //     MENUITEMS.filter(menuItem => {
    //         // eslint-disable-next-line
    //         if (menuItem != item) menuItem.active = false
    //         if (menuItem.children && menuItem.children.includes(item)) menuItem.active = true
    //         if (menuItem.children) {
    //             // eslint-disable-next-line
    //             menuItem.children.filter(submenuItems => {
    //                 if (submenuItems.children && submenuItems.children.includes(item)) {
    //                     menuItem.active = true
    //                     submenuItems.active = true
    //                 }
    //             })
    //         }
    //     })
    //     item.active = !item.active
    //     setMainMenu({ mainmenu: MENUITEMS })
    // }

    // Click Toggle menu
    const toggletNavActive = item => {
        // item.path && sessionStorage.setItem('url', item.path)
        if (!item.active) {
            MENUITEMS.forEach(a => {
                if (MENUITEMS.includes(item)) a.active = false
                if (!a.children) return false
                a.children.forEach(b => {
                    if (a.children.includes(item)) {
                        b.active = false
                    }
                    if (!b.children) return false
                    b.children.forEach(c => {
                        if (b.children.includes(item)) {
                            c.active = false
                        }
                    })
                })
            })
        }
        item.active = !item.active
        // === validar luego ===
        setMainMenu({ mainmenu: MENUITEMS })
    }

    const scrollToRight = () => {
        const elmnt = document.getElementById('myDIV')
        const menuWidth = elmnt?.offsetWidth || ''
        const temp = menuWidth + margin
        if (temp < menuWidth) {
            setMargin(-temp)
            setHideRightArrow(true)
        } else {
            setMargin(margin => (margin += -width))
            setHideLeftArrow(false)
        }
    }

    const scrollToLeft = () => {
        // If Margin is reach between screen resolution
        if (margin >= -width) {
            setMargin(0)
            setHideLeftArrow(true)
        } else {
            setMargin(margin => (margin += width))
            setHideRightArrow(false)
        }
    }

    const scrollToLeftRTL = () => {
        if (margin <= -width) {
            setMargin(margin => (margin += -width))
            setHideLeftArrowRTL(true)
        } else {
            setMargin(margin => (margin += -width))
            setHideRightArrowRTL(false)
        }
    }

    const scrollToRightRTL = () => {
        const temp = width + margin
        // Checking condition for remaing margin
        if (temp === 0) {
            setMargin(temp)
            setHideRightArrowRTL(true)
        } else {
            setMargin(margin => (margin += width))
            setHideRightArrowRTL(false)
            setHideLeftArrowRTL(false)
        }
    }

    return (
        <Fragment>
            <div className='page-sidebar div-sidebar'>
                <p className='noshow'>{mainmenu.length}</p>
                <div className='main-header-left d-none d-lg-block'>
                    {/* '#343a40' */}
                    <div className='logo-wrapper compactLogo p-3'>
                        <Link to={`/inicio`}>
                            <img className='blur-up lazyloaded' src={logo_compact} style={{ width: '100%' }} alt='' />
                            <img className='blur-up lazyloaded' src={logo} style={{ width: '100%' }} alt='' />
                        </Link>
                    </div>
                </div>
                {/* #14577D,, #f25d27 */}
                <div className='sidebar custom-scrollbar'>
                    <ul
                        className='sidebar-menu'
                        id='myDIV'
                        // style={
                        //     wrapper === 'horizontal_sidebar'
                        //         ? layout === 'rtl'
                        //             ? { marginRight: margin + 'px' }
                        //             : { marginLeft: margin + 'px' }
                        //         : { margin: '0px' }
                        // }
                    >
                        <li
                            className={`left-arrow ${layout === 'rtl' ? (hideLeftArrowRTL ? 'd-none' : '') : hideLeftArrow ? 'd-none' : ''}`}
                            onClick={wrapper === 'horizontal_sidebar' && layout === 'rtl' ? scrollToLeftRTL : scrollToLeft}
                            aria-hidden='true'
                        >
                            <i className='fa fa-angle-left'></i>
                        </li>

                        {loadingPermissions && (
                            <strong className='ml-5'>
                                <span> {!loadingPermissions && 'Cargando..'}</span>
                            </strong>
                        )}
                        {MENUITEMS.map((menuItem, i) => (
                            <li className={`${menuItem.active ? 'active' : ''}`} key={i}>
                                {menuItem.sidebartitle ? <div className='sidebar-title'>{menuItem.sidebartitle}</div> : ''}
                                {menuItem.type === 'sub' ? (
                                    <a href={menuItem.path} className='sidebar-header text-white' aria-hidden='true' onClick={() => toggletNavActive(menuItem)}>
                                        <menuItem.icon />
                                        <span>{props.t(menuItem.title)}</span>
                                        <i className='fa fa-angle-right pull-right'></i>
                                    </a>
                                ) : (
                                    ''
                                )}
                                {menuItem.type === 'link' ? (
                                    <Link
                                        to={`${menuItem.path}`}
                                        className={`sidebar-header ${menuItem.path === window.location.pathname ? 'active text-active' : 'text-white'}`}
                                        onClick={() => toggletNavActive(menuItem)}
                                    >
                                        <div className='row'>
                                            <div className='col-1'>
                                                <menuItem.icon />
                                            </div>
                                            <div className='col-10'>
                                                <span>{props.t(menuItem.title)}</span>
                                                {menuItem.children ? <i className='fa fa-angle-right pull-right'></i> : ''}
                                            </div>
                                        </div>
                                        {/* <menuItem.icon />
                                        <span>{props.t(menuItem.title)}</span>
                                        {menuItem.children ? <i className='fa fa-angle-right pull-right'></i> : ''} */}
                                    </Link>
                                ) : (
                                    ''
                                )}
                                {menuItem.type === 'exteral_link' ? (
                                    <a href={menuItem.path} className={`sidebar-header ${menuItem.active ? 'active' : ''}`}>
                                        <menuItem.icon />
                                        <span>{menuItem.title}</span>
                                    </a>
                                ) : (
                                    ''
                                )}
                                {menuItem.children ? (
                                    <ul
                                        className={`sidebar-submenu ${menuItem.active ? 'menu-open' : ''}`}
                                        // style={
                                        //     menuItem.active ? { opacity: 1, transition: 'opacity 500ms ease-in' } : {}
                                        // }
                                    >
                                        {menuItem.children.map((childrenItem, index) => (
                                            <li key={index} className={childrenItem.children ? (menuItem.active ? 'active' : '') : ''}>
                                                {childrenItem.type === 'sub' ? (
                                                    <h3 aria-hidden='true' onClick={() => toggletNavActive(childrenItem)}>
                                                        <i className='fa fa-circle'></i>
                                                        {props.t(childrenItem.title)} <i className='fa fa-angle-right pull-right'></i>
                                                    </h3>
                                                ) : (
                                                    ''
                                                )}

                                                {childrenItem.type === 'link' ? (
                                                    <Link
                                                        to={`${childrenItem.path}`}
                                                        className={childrenItem.path === window.location.pathname ? 'active text-active' : 'text-white'}
                                                        onClick={() => toggletNavActive(childrenItem)}
                                                    >
                                                        <i className='fa fa-circle'></i>
                                                        {props.t(childrenItem.title)}
                                                    </Link>
                                                ) : (
                                                    ''
                                                )}

                                                {childrenItem.children ? (
                                                    <ul className={`sidebar-submenu ${childrenItem.active ? 'menu-open' : 'active'}`}>
                                                        {childrenItem.children.map((childrenSubItem, key) => (
                                                            <li className={childrenSubItem.active ? 'active' : ''} key={key}>
                                                                {childrenSubItem.type === 'link' ? (
                                                                    <Link
                                                                        to={`${childrenSubItem.path}`}
                                                                        className={childrenSubItem.active ? 'active' : ''}
                                                                        onClick={() => toggletNavActive(childrenSubItem)}
                                                                    >
                                                                        <i className='fa fa-circle'></i>
                                                                        {props.t(childrenSubItem.title)}
                                                                    </Link>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    ''
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    ''
                                )}
                            </li>
                        ))}
                        <li
                            className={`right-arrow ${layout === 'rtl' ? (hideRightArrowRTL ? 'd-none' : '') : hideRightArrow ? 'd-none' : ''}`}
                            onClick={wrapper === 'horizontal_sidebar' && layout === 'rtl' ? scrollToRightRTL : scrollToRight}
                            aria-hidden='true'
                        >
                            <i className='fa fa-angle-right'></i>
                        </li>
                    </ul>
                </div>
            </div>
        </Fragment>
    )
}

export default translate(Sidebar)
