export const descriptions = {
    AA: 'Agente de aduanas',
    LM: 'Linea marítima',
    TR: 'Empresa de Transporte',
    FC: 'Facturación',
    CF: 'Cliente Final (Exportador/Importador)',
    CO: 'Consignatario de la línea marítima',
    VD: 'Ventanilla de Documentación',
    OB: 'Gestión Operaciones bodega/cobertizo',
    SC: 'Servicio al Cliente',
    PO: 'Policía',
    DI: 'Dian',
    IT: 'IT Compas',
    AC: 'Agente de carga'
}
