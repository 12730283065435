import React, { useEffect, useState } from 'react'
import moment from 'moment'
import 'moment-timezone'

const RelojColombia = () => {
    const [horaActual, setHoraActual] = useState('')

    useEffect(() => {
        const interval = setInterval(() => {
            const horaActual = moment().tz('America/Bogota').format('HH:mm:ss')
            setHoraActual(horaActual)
        }, 1000)
        return () => clearInterval(interval)
    }, [])

    return <span>Hora actual: {horaActual}</span>
}

export default RelojColombia
