import React from 'react'
import { ToastContainer } from 'react-toastify'
import Header from './common/header-component/header'
import Sidebar from './common/sidebar-component/sidebar'
import Footer from './common/footer'
import 'react-toastify/dist/ReactToastify.css'
// import Loader from './common/loader'

const AppLayout = ({ children }) => {
    return (
        <div>
            {/* <Loader /> */}
            <div className='page-wrapper'>
                <div className='page-body-wrapper wrapper-ccto'>
                    <Header />
                    <Sidebar />
                    <div className='page-body body-ccto'>{children}</div>
                    <Footer />
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default AppLayout
