/* eslint-disable */
import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { querysGraphqlUserVinculation } from '../../graphql/user-vinculation'
import { useKeycloak } from '@react-keycloak/web'
// import { useHistory } from 'react-router-dom'

// ====================== Registration of Requests ==========================
export const useFetchGetMenu = () => {
    // const history = useHistory()
    const { keycloak } = useKeycloak()
    const token = keycloak.token || null
    const [permissions, setPermissions] = useState([])
    const expiredSession = keycloak.isTokenExpired()
    const {
        data: dataPermissions,
        error: errorPermissions,
        loading: loadingPermissions
    } = useQuery(querysGraphqlUserVinculation.umUsersPermissions, {
        fetchPolicy: 'no-cache',
        variables: { token: token }
    })

    // const closeSession = async () => {
    //     if (errorPermissions.message.includes('JWTExpired')) {
    //         history.push('')
    //         const keysToDelete = ['exp_session', 'profile', 'token']
    //         keysToDelete.forEach(key => {
    //             localStorage.removeItem(key)
    //         })
    //         keycloak
    //             .logout()
    //             .then(() => {
    //                 window.location.replace('')
    //                 localStorage.clear()
    //                 keysToDelete.forEach(key => {
    //                     localStorage.removeItem(key)
    //                 })
    //                 window.location.href = '/'
    //             })
    //             .catch(error => {
    //                 console.error('Error al hacer logout: ', error)
    //             })
    //     }
    // }


    useEffect(() => {
        if (errorPermissions) {
            // closeSession()
        }

        if (errorPermissions && errorPermissions.message) {
            setPermissions([])
        }
        if (dataPermissions) {
            setPermissions(dataPermissions.umUsersPermissions.data || [])
        }
        return () => {}
    }, [dataPermissions, token, errorPermissions, expiredSession])

    return { permissions, loadingPermissions }
}
// ====== list about mandates =================
export const useFetchMandatesById = (idUser) => {
    const [mandates, setMandates] = useState([])

    const {
        data: dataMandates,
        error: errorMandates,
        loading: loadingMandates
    } = useQuery(querysGraphqlUserVinculation.manmMandatesById, {
        fetchPolicy: 'no-cache',
        variables: { idUser: idUser }
    })
    useEffect(() => {
        if (errorMandates) {
            setMandates([])
        }
        if (dataMandates) {
            setMandates(dataMandates.manmMandatesById.data || [])
        }
        return () => {}
    }, [dataMandates])
    return { mandates, loadingMandates }
}
