import React from 'react'
import logo_30 from '../../assets/images/logo_compas_30.png'
import Version from '../../components/common/version'

const Footer = () => {
    return (
        <footer className='footer footer-compas'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='content-img-ccto'>
                        <img className='full-img-compas' src={logo_30} alt='CCTO' />
                    </div>
                    <div className='texto'></div>
                    <div className='col-md-6 footer-copyright'>
                        <Version />
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
