import { useKeycloak } from '@react-keycloak/web'

const RenderOnAuthenticated = ({ children, publicPages }) => {
    const { keycloak } = useKeycloak()
    const pathname = window?.location?.pathname
    const showLayout = publicPages.some(({ path }) => path === pathname)

    return keycloak.authenticated && !showLayout ? children : false
}

export default RenderOnAuthenticated
