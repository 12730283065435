import configmap from '../config.json'
import Keycloak from 'keycloak-js'

const keycloak = new Keycloak({
    realm: configmap.REACT_APP_KEYCLOAK_REALM,
    url: configmap.REACT_APP_KEYCLOAK_URL,
    clientId: configmap.REACT_APP_KEYCLOAK_CLIENT_ID
})

export default keycloak
