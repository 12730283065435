import React, { useState, useEffect } from 'react'

const Loader = () => {
    const [show, setShow] = useState(true)

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShow(false)
        }, 1000)

        return () => {
            clearTimeout(timeout)
        }
    }, [])

    return (
        <>
            <div className={`loader-wrapper ${show ? '' : 'loaderhide'}`}>
                <div className='loader bg-white'>
                    <div className='ccto-loader'>
                        <div className='spinner-loader'>
                            <div className='bounce1'></div>
                            <div className='bounce2'></div>
                            <div className='bounce3'></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Loader
