import React from 'react'
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, ApolloLink } from '@apollo/client'
import configmap from '../config.json'

const url_graphql = configmap.REACT_APP_GRAPHQL_ENDPOINT

const ApolloAuthProvider = ({ children }) => {
    const httpLink = new HttpLink({ uri: url_graphql })

    const authLink = new ApolloLink((operation, forward) => {
        operation.setContext(() => ({}))
        return forward(operation)
    })

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    })

    return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default ApolloAuthProvider
