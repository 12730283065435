import React from 'react'
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, ApolloLink } from '@apollo/client'
import { useKeycloak } from '@react-keycloak/web'
import configmap from '../config.json'

// console.log('configmap ', configmap)
const url_graphql = configmap.REACT_APP_GRAPHQL_ENDPOINT

const ApolloAuthProvider = ({ children }) => {
    const { keycloak } = useKeycloak()
    const httpLink = new HttpLink({ uri: url_graphql })

    const profile = localStorage.getItem('profile')

    const authLink = new ApolloLink((operation, forward) => {
        operation.setContext(({ headers = { 'x-hasura-role': profile, Authorization: keycloak.token ? `Bearer ${keycloak.token}` : '' } }) => ({
            headers: {
                ...headers
            }
        }))

        return forward(operation)
    })

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    })

    return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default ApolloAuthProvider
