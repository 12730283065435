import React, { Fragment, useState } from 'react'
import { Key, User } from 'react-feather'
import { Popover, PopoverBody } from 'reactstrap'
import { withRouter } from 'react-router'
import logo_compact from '../../../assets/images/profile.png'
import { useKeycloak } from '@react-keycloak/web'
import { useFetchInitMenu } from '../../../hooks/menu'
import { globalContext } from '../../../provider/global.provider'
import { useHistory } from 'react-router-dom'

const UserMenu = () => {
    // const profile = sessionStorage.getItem('user')
    const [popover, setPopover] = useState(false)
    const OffsetToggle = () => setPopover(!popover)
    const { keycloak } = useKeycloak()
    const history = useHistory()
    const _globalContext = globalContext()
    const { mandates } = useFetchInitMenu()
    const { profile } = _globalContext
    // const { username, profile } = _globalContext
    // let nitAgente = ''
    // let nombreAgente = ''

    // const logout = () => {
    //     // history.push('')
    //     keycloak.logout() // usamos la instancia de keycloak obtenida con useKeycloak
    //     // setTimeout(() => {
    //     //     history.push('')
    //     // }, 2000)
    //     localStorage.clear()
    //     window.location.href = '/'
    // }

    const logout = () => {
        history.push('')
        const keysToDelete = ['exp_session', 'profile', 'token']
        keysToDelete.forEach(key => {
            localStorage.removeItem(key)
        })
        keycloak
            .logout()
            .then(() => {
                window.location.replace('')
                localStorage.clear()
                keysToDelete.forEach(key => {
                    localStorage.removeItem(key)
                })
            })
            .catch(error => {
                console.error('Error al hacer logout: ', error)
            })
    }

    const editProfile = () => {
        history.push('/editar-perfil')
    }

    // if (profile === 'AA') {
    //     nitAgente = mandates[0]?.nitDelegate
    //     nombreAgente = mandates[0]?.nameDelegate
    // } else {
    //     nitAgente = mandates[0]?.nitClient
    //     nombreAgente = mandates[0]?.nameClient
    // }

    // AA = DELEGATE
    // CF = CLIENT
    // OTROS = CLIENT

    return (
        <Fragment>
            <ul>
                <li id='Popover'>
                    <div
                        className='text-center cursor'
                        style={{
                            width: '290px',
                            textShadow: '0 0 black',
                            fontStretch: 'extra-condensed'
                        }}
                    >
                        <ul className='right-name-profile'>
                            <li style={{ verticalAlign: 'text-bottom' }}>
                                <div className='name-profile'>
                                    {mandates[0]?.luCompany} - {mandates[0]?.luNit} -  {mandates[0]?.luName}
                                </div>
                                <img className='img-profile rounded-circle' src={logo_compact} alt={profile}></img>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
            <Popover placement='bottom' isOpen={popover} target='Popover' toggle={OffsetToggle} offset='0px' trigger='hover'>
                <PopoverBody>
                    <div className='media align-items-left'>
                        <ul className='profile-dropdown p-10'>
                            <li className='close-session-ccto'>
                                <div onClick={editProfile} aria-hidden='true' className='btn-close'>
                                    <User />
                                    <span>Editar mi perfil</span>
                                </div>
                            </li>
                        </ul>
                        <ul className='profile-dropdown p-10'>
                            <li className='close-session-ccto'>
                                <div onClick={logout} aria-hidden='true' className='btn-close'>
                                    <Key />
                                    <span>Cerrar session</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </PopoverBody>
            </Popover>
        </Fragment>
    )
}

export default withRouter(UserMenu)
