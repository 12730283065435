/* eslint-disable */
import React, { createContext, useContext, useState, useEffect } from 'react'
import { useFetchInitMenu } from '../hooks/menu'
import { useKeycloak } from '@react-keycloak/web'
import jwt_decode from 'jwt-decode'
import configmap from '../config.json'

const GlobalContext = createContext({
    idUser: null,
    username: '',
    profile: '',
    mandatos: []
})

export const globalContext = () => {
    return useContext(GlobalContext)
}

export const GlobalProvider = ({ children }) => {
    const { keycloak } = useKeycloak()
    const token = keycloak.token || null
    // console.log('token: ', token)
    // console.log('configmap', configmap)
    const decoded = keycloak.token && jwt_decode(keycloak.token)
    // const listRols = decoded && decoded['https://hasura.io/jwt/claims']['x-hasura-allowed-roles']
    const newListRols = decoded && decoded.resource_access?.[configmap.REACT_APP_KEYCLOAK_CLIENT_ID]?.roles
    // const rol = listRols.filter(val => val !== 'offline_access' && val !== 'default-roles-compas-dev' && val !== 'uma_authorization')
    const rol = newListRols?.filter(val => val !== 'clientes' && val !== 'default-roles-compas-dev' && val !== 'uma_authorization')
    const { mandates, idUser, currentDate, FetchGetMenu } = useFetchInitMenu()

    const { permissions, loadingPermissions } = FetchGetMenu

    const rolSelect = localStorage.getItem('profile')
    const profile = rolSelect

    const [infoSession, setInfoSession] = useState({
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        id: ''
    })

    useEffect(() => {
        keycloak.loadUserInfo().then(result => {
            const { family_name, given_name, email, preferred_username, sub } = result

            setInfoSession({
                ...infoSession,
                firstName: family_name,
                lastName: given_name,
                email: email,
                username: preferred_username,
                id: sub
            })
        })
    }, [token])

    return (
        <GlobalContext.Provider
            value={{
                ...infoSession,
                mandatos: mandates,
                idUser,
                profile,
                currentDate,
                rol: rolSelect || '',
                roles: rol,
                token: token || 0,
                permissions,
                loadingPermissions
            }}
        >
            {children}
        </GlobalContext.Provider>
    )
}
