import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import keycloak from './config/keycloak'
import ApolloProvider from './provider/ApolloProvider'
import ApolloPublicProvider from './provider/ApolloPublicProvider'
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web'

import RenderOnAnonymous from './components/login/RenderOnAnonymous'
import RenderOnAuthenticated from './components/login/RenderOnAuthenticated'

// ---- carga de sass
import './index.scss'

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { ScrollContext } from 'react-router-scroll-4'
import * as serviceWorker from './serviceWorker'

// ** Import custom components for redux**
import { Provider } from 'react-redux'
import store from './store/index'
import { GlobalProvider } from './provider/global.provider'

import App from './components/app'
import Loader from './components/common/loader'

const Error400 = React.lazy(() => import('./components/common/errors/error400'))
const Error403 = React.lazy(() => import('./components/common/errors/error403'))
const Error404 = React.lazy(() => import('./components/common/errors/error404'))
const Error500 = React.lazy(() => import('./components/common/errors/error500'))
const Error503 = React.lazy(() => import('./components/common/errors/error503'))
const Error504 = React.lazy(() => import('./components/common/errors/error504'))

const Welcome = React.lazy(() => import('./components/Welcome'))
const SelectProfile = React.lazy(() => import('./components/select-profile'))
const Inicio = React.lazy(() => import('./components/inicio'))
const EditarPerfil = React.lazy(() => import('./components/editar-perfil'))

const Bookings = React.lazy(() => import('./components/bookings'))
// const BookingManual = React.lazy(() => import('./components/bookings/crear-booking'))
// Para crear booking nuevo lleno y vacio ======================
const BookingLLeno = React.lazy(() => import('./components/bookings/crear-booking-lleno'))
const ReservaVacio = React.lazy(() => import('./components/bookings/crear-reserva-vacio'))
// --------------------------------- Fin -----------------------------------------
const EditarBooking = React.lazy(() => import('./components/bookings/editar-booking'))
const ReporteSolicitudesExportacion = React.lazy(() => import('./components/solicitud-arim/reporte-solicitudes-exportacion'))
const ReporteLlenadoContenedores = React.lazy(() => import('./components/solicitud-arim/reporte-llenado-contenedores'))
// =====================================================================
const SolictudAricIngreso = React.lazy(() => import('./components/solicitud-arim/creacion-solicitud-exportacion'))
const ConsultaContenedorImportacion = React.lazy(() => import('./components/solicitud-arim/consulta-contenedor-aric'))
const EditarSolicitudImportacion = React.lazy(() => import('./components/solicitud-arim/editar-solicitud-importacion'))
const CreacionSolicitudImportacion = React.lazy(() => import('./components/solicitud-arim/creacion-solicitud-importacion'))
// RUTA PARA EL DE VACIO INGRESO CONTENDOR: BOOKING
const BookingVacioArim = React.lazy(() => import('./components/solicitud-arim/reporte-solicitudes-exportacion/booking-arim-vacio'))

// Esto para facturación
const FacturacionIngresoContenedor = React.lazy(() => import('./components/facturacion/reporte-ingreso-contenedor'))
const FacturacionRetiroContenedor = React.lazy(() => import('./components/facturacion/reporte-retiro-contenedor'))
const FacturacionRetiroVacios = React.lazy(() => import('./components/facturacion/reporte-retiro-de-vacios'))

// Esto para centro de documentos
const CentroDocumentosIngresoContenedor = React.lazy(() => import('./components/centro-documentos/reporte-ingreso-contenedor'))
const CentroDocumentosRetiroContenedor = React.lazy(() => import('./components/centro-documentos/reporte-retiro-contenedor'))
const CentroDocumentosRetiroVacios = React.lazy(() => import('./components/centro-documentos/reporte-retiro-de-vacios'))
const GestionSolicitudesMandatos = React.lazy(() => import('./components/mandatos/gestion-solicitudes'))
const CentroDocumentosReporteValoresAduaneros = React.lazy(() => import('./components/centro-documentos/reporte-valores-aduaneros'))
// RUTAS NUEVAS PARA EL DE TRANSACCIONES
const TransaccionExport = React.lazy(() => import('./components/centro-documentos/transaccion-export'))
const transaccionImport = React.lazy(() => import('./components/centro-documentos/trasanccion-import'))
const TransaccionVacio = React.lazy(() => import('./components/centro-documentos/transaccion-vacio'))
// ---------------------------------------
// ----- este reporte no se de que es -----
const ReporteCliente = React.lazy(() => import('./components/report-cliente'))
// --------------------------
const IngresoContenedor = React.lazy(() => import('./components/ingreso-contenedor'))
const ReporteRetiroDeVacios = React.lazy(() => import('./components/solicitud-arim/retiros-vacios'))

const ConfiguracionHorarios = React.lazy(() => import('./components/citas/configuracion-horarios'))
const GeneracionDeCitas = React.lazy(() => import('./components/citas/generacion-de-citas'))
// const AsignacionOrdenesAEmpresaTransporte = React.lazy(() => import('./components/citas/asignacion-ordenes-a-empresa-transporte'))
// const RecuperacionContenedoresCaducadosImport = React.lazy(() => import('./components/citas/recuperacion-contenedores-caducados-import'))

const UsuariosInternos = React.lazy(() => import('./components/seguridad/usuarios-internos'))
const CreacionUsuarioInterno = React.lazy(() => import('./components/seguridad/creacion-usuario-interno'))
const SolicitudVinculacionUsuario = React.lazy(() => import('./components/solicitud-vinculacion-usuario'))
const SolicitudesVinculacionUsuario = React.lazy(() => import('./components/servicio-al-cliente/solicitudes-vinculacion-usuario'))
const ProgramacionDeSolicitudes = React.lazy(() => import('./components/operaciones-logistica/programacion-de-solicitudes'))
const SolicitudesIngresoLlenoOps = React.lazy(() => import('./components/operaciones-logistica/solicitudes-de-ingreso-lleno-ops'))
const ProgramacionDeInspecciones = React.lazy(() => import('./components/operaciones-logistica/solicitud-de-inspeccion'))
// ===============REGISRO DE SOLICITUDES======================

const ReporteDeSolicitudes = React.lazy(() => import('./components/solicitudes/registro-de-solicitudes/reporte-solicitudes'))
const IngresoDeSolicitud = React.lazy(() => import('./components/solicitudes/registro-de-solicitudes/ingreso-de-solicitud'))
// const ModificarSolicitud = React.lazy(() => import('./components/solicitudes/registro-de-solicitudes/modificar-solicitud'))
// const VerSolicitud = React.lazy(() => import('./components/solicitudes/registro-de-solicitudes/ver-solicitud'))
// ====================================
const ReporteSolicitudDeVaciado = React.lazy(() => import('./components/solicitudes/solicitud-de-vaciado/reporte-solicitudes'))
const IngresoDeSolicitudDeVaciado = React.lazy(() => import('./components/solicitudes/solicitud-de-vaciado/ingreso-de-solicitud'))
const ModificarSolicitudDeVaciado = React.lazy(() => import('./components/solicitudes/solicitud-de-vaciado/modificar-solicitud'))
const VerSolicitudDeVaciado = React.lazy(() => import('./components/solicitudes/solicitud-de-vaciado/ver-solicitud'))

const ReporteSolicitudDeMotonave = React.lazy(() => import('./components/solicitudes/solicitud-de-motonave/reporte-solicitudes'))
const IngresoDeSolicitudDeMotonave = React.lazy(() => import('./components/solicitudes/solicitud-de-motonave/ingreso-de-solicitud'))

// ============================ REPORTS ============================
const ConsultaDeContenedores = React.lazy(() => import('./components/reportes/consulta-contenedores'))
const ConsultaDeInventario = React.lazy(() => import('./components/reportes/consulta-inventario'))
const ConsultaDeInspeccion = React.lazy(() => import('./components/reportes/consulta-inspeccion'))
const ConsultaDeContenedoresPorPuerta = React.lazy(() => import('./components/reportes/consulta-contenedores-por-puerta'))
const ConsultaFacturas = React.lazy(() => import('./components/reportes/consulta-facturas'))
const IngresoContenedoresPorPuerta = React.lazy(() => import('./components/reportes/ingreso-contenedores-por-puerta'))
const ConsultaDeCitas = React.lazy(() => import('./components/reportes/consulta-de-citas'))

const ConsultaBookings = React.lazy(() => import('./components/reportes/consulta-bookings'))
const ConsultaBookingsBl = React.lazy(() => import('./components/reportes/consulta-bookings-bl'))
const ConsultaMandatos = React.lazy(() => import('./components/reportes/consulta-mandatos'))
const IngresoSolicitudInspeccion = React.lazy(() => import('./components/solicitudes/solicitudes-de-inspeccion/ingreso-solicitud-de-inspeccion'))
const ReporteSolicitudInspeccion = React.lazy(() => import('./components/solicitudes/solicitudes-de-inspeccion/reporte-solicitud-de-inspeccion'))
const ReporteMandatos = React.lazy(() => import('./components/reportes/mandatos'))
const ReporteRetenciones = React.lazy(() => import('./components/reportes/reporte-retenciones'))
// usuario externos
const PermisosUsuarios = React.lazy(() => import('./components/seguridad/usuarios-registrados'))

// configuracion-sistema
const ConfiguracionSistema = React.lazy(() => import('./components/seguridad/configuracion-sistema'))
const EmailGroup = React.lazy(() => import('./components/seguridad/email-group'))
const ReportsLogs = React.lazy(() => import('./components/seguridad/report-logs'))
const ReportLogsLogistic = React.lazy(() => import('./components/seguridad/logs-logistics'))
const ReportsLogsAppointment = React.lazy(() => import('./components/seguridad/report-logs-appointment'))
const ConfigLineaNaviera = React.lazy(() => import('./components/seguridad/config-linea-naviera'))
const ReportLogMessageKey = React.lazy(() => import('./components/seguridad/report-logs-msgkey'))
const TipoSolicitud = React.lazy(() => import('./components/seguridad/tipo-solicitud'))
const NitCreadit = React.lazy(() => import('./components/servicio-al-cliente/clientes-credito'))

// asignación de permisos usuarios externos
const PermisosUsuariosAsignar = React.lazy(() => import('./components/seguridad/permisos-usuarios-asignar'))

// permisos usuario por rol
const PermisosUsuariosPorRol = React.lazy(() => import('./components/seguridad/permisos-por-rol'))
const PermisosUsuariosAsignarPorRol = React.lazy(() => import('./components/seguridad/permisos-rol-asignar'))
const DocumentosPorModalidad = React.lazy(() => import('./components/seguridad/documentos-por-modalidad'))
const AsociarModalidadDocumento = React.lazy(() => import('./components/seguridad/asociar-modadlidad-documento'))

const VinculacionDeUsuarios = React.lazy(() => import('./components/vinculacion-usuarios/vinculacion-de-usuario-publico'))
const RecuperarCredenciales = React.lazy(() => import('./components/seguridad/recuperar-credenciales'))
const EnvioRecuperacionCredenciales = React.lazy(() => import('./components/seguridad/envio-recuperacion-credenciales'))
const ProgramacionBuques = React.lazy(() => import('./components/reportes/programacion-de-buques'))
const DisponibilidadDeCitas = React.lazy(() => import('./components/reportes/disponibilidad-de-citas'))
const CambiarContrasena = React.lazy(() => import('./components/vinculacion-usuarios/cambiar-contrasena'))
const PortalesExternos = React.lazy(() => import('./components/common/redirect'))

const publicPages = [
    { component: VinculacionDeUsuarios, path: '/vinculacion-de-usuarios' },
    { component: RecuperarCredenciales, path: '/restablecer-credenciales/:code' },
    { component: EnvioRecuperacionCredenciales, path: '/recuperacion-credenciales' },
    { component: ProgramacionBuques, path: '/reporte-programacion-de-buques' },
    { component: DisponibilidadDeCitas, path: '/disponibilidad-de-citas' },
    { component: CambiarContrasena, path: '/cambiar-contraseña' }
]

// const RenewTokenInterval = 600000 // Intervalo de renovación en milisegundos (10 minutos)
// const RenewTokenInterval = 120000 // 2 minutos en milisegundos
const RenewTokenInterval = 60000 // 1 minuto en milisegundos

const Root = () => {
    const { initialized } = useKeycloak()
    // const abortController = new AbortController()
    const profile = localStorage.getItem('profile')
    
    if (!initialized) {
        return <Loader />
    }


    // const refreshToken = () => {
    //     keycloak.updateToken()
    //       .then(refreshed => {
    //         if (refreshed) {
    //           console.log('Token renovado')
    
    //         } else {
    //           console.log('No se pudo renovar el token');
    //         }
    //       })
    //       .catch(error => {
    //         console.error('Error al renovar el token', error);
    //       });
    //   };    

    // useEffect(() => {
    //     return function cleanup() {
    //         abortController.abort()
    //     }
    // })


    return (
        <div className='App'>
            <ApolloPublicProvider>
                <BrowserRouter basename={'/'}>
                    <Suspense fallback={<Loader />}>
                        <Switch>
                            {publicPages.map(({ path, component }) => {
                                return <Route key={path} path={path} component={component} />
                            })}
                        </Switch>
                    </Suspense>
                </BrowserRouter>
            </ApolloPublicProvider>

            <ApolloProvider>
                <Provider store={store}>
                    <BrowserRouter basename={'/'}>
                        <ScrollContext>
                            <Suspense fallback={<Loader />}>
                                <RenderOnAnonymous>
                                    <Switch>
                                        <Route exact path='/' component={Welcome} />
                                    </Switch>
                                </RenderOnAnonymous>
                                <RenderOnAuthenticated publicPages={publicPages}>
                                    <Route exact path='/profile' component={SelectProfile} />
                                    <Route exact path='/error-400' component={Error400} />
                                    <Route exact path='/error-403' component={Error403} />
                                    <Route exact path='/error-404' component={Error404} />
                                    <Route exact path='/error-500' component={Error500} />
                                    <Route exact path='/error-504' component={Error504} />
                                    <Route exact path='/error-503' component={Error503} />

                                    {profile ? (
                                        <GlobalProvider>
                                            <App>
                                                <Route path='/inicio' component={Inicio} />
                                                <Route path='/editar-perfil' component={EditarPerfil} />
                                                <Route path='/bookings' component={Bookings} />
                                                {/* <Route path='/crear-booking' component={BookingManual} /> */}
                                                {/* // PARA BOOKING LLENO Y VACIO  */}
                                                <Route path='/crear-reserva-vacio' component={ReservaVacio} />
                                                <Route path='/crear-booking-lleno' component={BookingLLeno} />
                                                {/* ------------------- Fin -----------------*/}
                                                <Route path='/editar-booking' component={EditarBooking} />
                                                <Route path='/solicitud-aric-ingreso' component={SolictudAricIngreso} />
                                                {/* RUTA PARA EL DE VACIO INGRESO CONTENDOR: BOOKING */}
                                                <Route path='/booking-arim-vacio' component={BookingVacioArim} />
                                                <Route path='/consulta-contenedor-importacion' component={ConsultaContenedorImportacion} />
                                                <Route path='/editar-solicitud-aric-importacion' component={EditarSolicitudImportacion} />
                                                <Route path='/crear-solicitud-aric-importacion' component={CreacionSolicitudImportacion} />
                                                <Route path='/centro-documentos-ingreso-contenedor' component={CentroDocumentosIngresoContenedor} />
                                                <Route path='/centro-documentos-retiro-contenedor' component={CentroDocumentosRetiroContenedor} />
                                                <Route path='/centro-documentos-retiro-de-vacios' component={CentroDocumentosRetiroVacios} />
                                                <Route path='/reporte-valores-aduaneros' component={CentroDocumentosReporteValoresAduaneros} />
                                                {/* Aqui empezaron las rutas para el tipo transaccion export import y vacio */}
                                                <Route path='/transaccion-export' component={TransaccionExport} />
                                                <Route path='/transaccion-import' component={transaccionImport} />
                                                <Route path='/transacciones-vacio' component={TransaccionVacio} />
                                                {/* ----------------------------------------------------------------------- */}
                                                {/* reporte de llenado de contenedores */}
                                                <Route path='/llenado-de-contenedores' component={ReporteLlenadoContenedores} />
                                                {/* ----------------------------------- */}
                                                <Route path='/reporte-solicitud-mandatos' component={GestionSolicitudesMandatos} />
                                                <Route path='/reporte-eleccion-cliente' component={ReporteCliente} />
                                                <Route path='/ingreso-contenedor' component={IngresoContenedor} />
                                                <Route path='/solicitudes-exportacion' component={ReporteSolicitudesExportacion} />
                                                <Route path='/retiro-vacios' component={ReporteRetiroDeVacios} />
                                                <Route path='/configuracion-de-horarios' component={ConfiguracionHorarios} />
                                                <Route path='/generacion-citas' component={GeneracionDeCitas} />
                                                <Route path='/solicitud-vinculacion-usuario' component={SolicitudVinculacionUsuario} />
                                                <Route path='/solicitudes-de-vinculacion' component={SolicitudesVinculacionUsuario} />
                                                <Route path='/creacion-usuario-interno' component={CreacionUsuarioInterno} />
                                                <Route path='/usuarios-internos' component={UsuariosInternos} />
                                                <Route path='/programacion-de-solicitudes' component={ProgramacionDeSolicitudes} />
                                                <Route path='/solicitudes-de-inspeccion' component={ProgramacionDeInspecciones} />
                                                <Route path='/solicitudes-de-ingreso-lleno-ops' component={SolicitudesIngresoLlenoOps} />
                                                {/* REGISTRO DE SOLICITUDES */}
                                                <Route path='/reporte-de-solicitudes' component={ReporteDeSolicitudes} />
                                                <Route path='/ingreso-de-solicitud' component={IngresoDeSolicitud} />
                                                {/* <Route path='/modificar-solicitud' component={ModificarSolicitud} /> */}
                                                {/* <Route path='/ver-solicitud' component={VerSolicitud} /> */}
                                                <Route path='/ingreso-solicitud-inspeccion' component={IngresoSolicitudInspeccion} />
                                                <Route path='/reporte-solicitud-inspeccion' component={ReporteSolicitudInspeccion} />
                                                <Route path={'/reporte-solicitud-de-vaciado'} component={ReporteSolicitudDeVaciado} />
                                                <Route path={'/ingreso-solicitud-de-vaciado'} component={IngresoDeSolicitudDeVaciado} />
                                                <Route path={'/modificar-solicitud-de-vaciado'} component={ModificarSolicitudDeVaciado} />
                                                <Route path={'/ver-solicitud-de-vaciado'} component={VerSolicitudDeVaciado} />
                                                <Route path={'/reporte-solicitud-de-motonave'} component={ReporteSolicitudDeMotonave} />
                                                <Route path={'/ingreso-solicitud-de-motonave'} component={IngresoDeSolicitudDeMotonave} />
                                                {/* REGISTRO DE SOLICITUDES */}

                                                {/* REPORTES */}
                                                <Route path='/consulta-factura-pagos' component={ConsultaFacturas} />
                                                <Route path='/consulta-de-contenedores' component={ConsultaDeContenedores} />
                                                <Route path='/consulta-de-inventario' component={ConsultaDeInventario} />
                                                <Route path='/consulta-inspeccion' component={ConsultaDeInspeccion} />
                                                <Route path='/consulta-de-contenedores-por-puerta' component={ConsultaDeContenedoresPorPuerta} />
                                                <Route path='/ingreso-contenedores-por-puerta' component={IngresoContenedoresPorPuerta} />
                                                <Route path='/consulta-de-citas' component={ConsultaDeCitas} />
                                                <Route path='/consulta-bookings' component={ConsultaBookings} />
                                                <Route path='/consulta-bookings-bl' component={ConsultaBookingsBl} />
                                                <Route path='/consulta-mandatos' component={ConsultaMandatos} />
                                                <Route path='/reporte-mandatos' component={ReporteMandatos} />
                                                <Route path='/retenciones' component={ReporteRetenciones} />
                                                
                                                {/* REPORTES */}

                                                {/* PERMISOS DE USUARIOS */}
                                                <Route path='/permisos-usuarios' component={PermisosUsuarios} />
                                                <Route path='/configuracion-sistema' component={ConfiguracionSistema} />
                                                <Route path='/email-group' component={EmailGroup} />
                                                <Route path='/reporte-logs' component={ReportsLogs} />
                                                <Route path='/reporte-logs-logisticos' component={ReportLogsLogistic} />
                                                <Route path='/reporte-logs-appointment' component={ReportsLogsAppointment} />
                                                <Route path='/lineas-navieras' component={ConfigLineaNaviera} />
                                                <Route path='/reporte-logs-message-key' component={ReportLogMessageKey} />
                                                <Route path='/tipo-de-solicitud' component={TipoSolicitud} />
                                                <Route path='/clientes-credito' component={NitCreadit} />
                                                <Route path='/permisos-usuarios-asignar' component={PermisosUsuariosAsignar} />
                                                <Route path='/permisos-usuarios-por-rol' component={PermisosUsuariosPorRol} />
                                                <Route path='/permisos-usuarios-asignar-por-rol' component={PermisosUsuariosAsignarPorRol} />
                                                <Route path='/documentos-por-modalidad' component={DocumentosPorModalidad} />
                                                <Route path='/asociar-modalidad-documentos' component={AsociarModalidadDocumento} />
                                                {/* PERMISOS DE USUARIOS */}

                                                <Route path='/facturacion-ingreso-contenedor' component={FacturacionIngresoContenedor} />
                                                <Route path='/facturacion-retiro-contenedor' component={FacturacionRetiroContenedor} />
                                                <Route path='/facturacion-retiro-de-vacios' component={FacturacionRetiroVacios} />
                                                <Route 
                                                    path='/pago-en-linea' 
                                                    render={(props) => <PortalesExternos variable='1' {...props} />}
                                                />
                                                <Route 
                                                    path='/registra-email-fe' 
                                                    render={(props) => <PortalesExternos variable='3' {...props} />}
                                                />
                                                <Route
                                                    path='/solicita-factura'
                                                    render={(props) => <PortalesExternos variable='2' {...props} />}
                                                />
                                            </App>
                                        </GlobalProvider>
                                    ) : (
                                        <Redirect to={`/profile`} />
                                    )}
                                </RenderOnAuthenticated>
                            </Suspense>
                        </ScrollContext>
                    </BrowserRouter>
                </Provider>
            </ApolloProvider>
        </div>
    )
}

ReactDOM.render(
    <ReactKeycloakProvider authClient={keycloak}>
        <TokenRenewalWrapper>
            {({ tokenRefreshed, tokenError }) => (
                <Root tokenRefreshed={tokenRefreshed} tokenError={tokenError} />
            )}
        </TokenRenewalWrapper>
    </ReactKeycloakProvider>,
    document.getElementById('root')
)

// Función para renovar el token automáticamente
function TokenRenewalWrapper({ children }) {
    const { keycloak } = useKeycloak()
    const [tokenRefreshed, setTokenRefreshed] = React.useState(false)
    const [tokenError, setTokenError] = React.useState(null)
    
  
    React.useEffect(() => {    
  
        // Iniciar la renovación automática del token
        const renewalInterval = setInterval(() => {
            keycloak
                .updateToken()
                .then((refreshed) => {
                    setTokenRefreshed(refreshed)
                    setTokenError(null) // Reiniciar el error si se actualiza con éxito                    
                    // if (refreshed) {
                    //     console.log('Token renovado con éxito')
                    // } else {
                    //     console.error('No se pudo renovar el token: El token no fue renovado')
                    // }
                })
                .catch((error) => {
                    setTokenRefreshed(false)
                    setTokenError(error)
                    // ... (código de redirección si es necesario)                    
                    // console.error('Error al renovar el token:', error)
                    // Obtén el host actual
                    // Verifica si la ubicación actual es una página pública
                    const isPublicPage = publicPages.some((publicPage) => location.pathname === publicPage.path)
                    // console.log('isPublicPage: ',isPublicPage)

                    if (!isPublicPage) {
                        // Realiza la redirección solo si no es una página pública
                        const { host } = window.location
                        window.location.href = `//${host}`
                    }                    

                    // const { host } = window.location
                    // console.log('host: ',host)
                    // window.location.href = `//${host}`
                })
        }, RenewTokenInterval)
      
  
        // Limpiar el intervalo al desmontar el componente
        return () => {
            clearInterval(renewalInterval)
            
        }
      
    }, [keycloak])
    
  
    // return children
    return children({ tokenRefreshed, tokenError })
    
}

serviceWorker.unregister()
