import { gql } from '@apollo/client'

const umTypeUser = gql`
    query MyQuery($typeUser: String!) {
        umTypeUser(type: $typeUser) {
            data {
                name
                id
            }
            message
        }
    }
`
// ==== creaciòn de usuario =====
const umRegisterUser = gql`
    mutation MyMutation(
        $name: String!
        $surname: String!
        $email: String!
        $nit: String!
        $phone: String!
        $company: String!
        $password_temp: String!
        $password_temp_confirmed: String!
        $created_by: String!
        $type_id: Int!
        $dni: String!
        $otm: Boolean
        $fileUser: [fileUser]
        $secondSurname: String!
    ) {
        umRegisterUser(
            company: $company
            created_by: $created_by
            email: $email
            name: $name
            nit: $nit
            password_temp: $password_temp
            password_temp_confirmed: $password_temp_confirmed
            phone: $phone
            surname: $surname
            type_id: $type_id
            dni: $dni
            otm: $otm
            fileUser: $fileUser
            secondSurname: $secondSurname
        ) {
            data {
                userVinculationId
                response
                message
            }
            message
        }
    }
`
const umUsersRegistered = gql`
    query MyQuery($typeUser: String!) {
        umUsersRegistered(typeUser: $typeUser) { 
            data {
                company
                createdAt
                createdBy
                usern
                updatedBy
                updatedAt
                typeUser
                surname
                secondSurname
                rolServiceOrder
                rolName
                rolCode
                requestStatus
                reasonRejection
                phone
                otm
                nit
                name
                dni
                email
                id
                deletedAt
                deletedBy
                attendedBy
                attendedAt
            }
            message
            }
        }
`

const umDenyUserRequest = gql`
    mutation MyMutation($idUser: Int!, $deleted_by: String!, $reasonRejection: String!, $name: String!, $surname: String!, $email: String!) {
        umDenyUserRequest(
            deleted_by: $deleted_by
            idUser: $idUser
            reasonRejection: $reasonRejection
            name: $name
            surname: $surname
            email: $email
        ) {
            message
            data {
                userVinculationId
                message
            }
        }
    }
`

const umAcceptUsersRequest = gql`
    mutation MyMutation($idUser: Int!, $updated_by: String!, $name: String!, $surname: String!, $email: String!) {
        umAcceptUsersRequest(updated_by: $updated_by, idUser: $idUser, name: $name, surname: $surname, email: $email) {
            message
            data {
                systemUserId
                message
            }
        }
    }
`

const umUsersPermissions = gql`
    query MyQuery($token: String!) {
        umUsersPermissions(token: $token) {
            message
            data {
                code
                condition
                id
                idUser
                permisionCode
                user
                path
            }
        }
    }
`
const umCreateDocument = gql`
    mutation MyMutation(
        $userId: Int!
        $filename: String!
        $category: String!
        $namespace: String!
        $extension: String!
        $attachment: String!
        $created_by: String!
    ) {
        umCreateDocument(
            userId: $userId
            filename: $filename
            category: $category
            namespace: $namespace
            extension: $extension
            attachment: $attachment
            created_by: $created_by
        ) {
            message
        }
    }
`
const umDocumentByUser = gql`
    query MyQuery($idUser: Int!) {
        umDocumentByUser(idUser: $idUser) {
            message
            data {
                category
                file_name
                id
                namespace
            }
        }
    }
`

const umDocument = gql`
    query MyQuery(
            $filename: String!,
            $category: String!,
            $namespace: String!
        ) {
        umDocument(
            category: $category,
            filename: $filename,
            namespace: $namespace
        ) {
            message
            data {
                detail
                file
                name
            }
        }
    }
`

const umUserInformation = gql`
    query MyQuery {
        umUserInformation(userName: "-") {
            data {
                company
                dni
                email
                id
                name
                nit
                phone
                surname
                second_surname
                keycloak_uuid
            }
            message
        }
    }
`

const umUpdateUserInformation = gql`
    mutation MyMutation(
        $id: Int!
        $company: String!
        $dni: String!
        $email: String!
        $name: String!
        $nit: String!
        $phone: String!
        $surname: String!
        $second_surname: String!
        $updatedBy: String!
        $psw: String
        $keycloak_uuid: String
    ) {
        umUpdateUserInformation(
            id: $id
            company: $company
            dni: $dni
            email: $email
            name: $name
            nit: $nit
            phone: $phone
            surname: $surname
            second_surname: $second_surname
            updatedBy: $updatedBy
            psw: $psw
            keycloak_uuid: $keycloak_uuid
        ) {
            data {
                message
                response
            }
            message 
        }
    }
`

// ===== mandatos para el provider ===================
const manmMandatesById = gql`
    query MyQuery($idUser: Int!) {
        manmMandatesById(idUser: $idUser) {
            message
            data {
                id
                nitClient
                nameClient
                nitDelegate
                nameDelegate
                expirationDate
                status
                currentDate
                profile
                luNit
                luName
                luEmail
                luCompany                
            }
        }
    }
`

const umRequestResetPassword = gql`
    mutation MyMutation($userName: String!, $email: String!) {
        umRequestResetPassword(userName: $userName, email: $email) {
            message
            data {
                message
                response
            }
        }
    }
`
const umResetPassword = gql`
    mutation MyMutation($new_password: String!, $new_password_confirmed: String!, $token: String!) {
        umResetPassword(new_password: $new_password, new_password_confirmed: $new_password_confirmed, token: $token) {
            data {
                message
                response
            }
            message
        }
    }
`

const fmDocuments = gql`
    query MyQuery($category: String!, $filename: String!, $namespace: String!) {
        fmDocuments(category: $category, filename: $filename, namespace: $namespace) {
            message
            data {
                title
                pat
                name
                file
                detail
            }
        }
    }
`

const reqmDocuments = gql`
    query MyQuery($requestId: Int!) {
        reqmDocuments(requestId: $requestId) {
            message
            data {
                category
                createdAt
                createdBy
                documentId
                filename
                namespace
                path
            }
        }
    }
`
// ================== Mostrar los nit en segurida ============
const secFetchNit = gql`
    query MyQuery {
        secFetchNit {
            message
            data {
                flag
                message
                request {
                    createBy
                    dateCreate
                    dateEdit
                    dateUpdate
                    estado
                    id
                    nitCliente
                    timeCreate
                    updatedBy
                    timeUpdate
                    clienteCredito
                    aplicaVacio
                    aplicaLleno
                    tipoImportacion
                    tipoExportacion
                    generaFactura
                    nombreCliente
                    fechaVigenciaCredito
                    observaciones
                }
            }
        }
    }
`
// ============================= Insertar Nit ==============
const secCreateNit = gql`
    mutation MyMutation(
        $nitCliente: String!
        $userName: String!
        $clienteCredito: String!
        $generaFactura: String!
        $aplicaVacio: String!
        $aplicaLleno: String!
        $nombreCliente: String!
        $tipoImportacion: String!
        $tipoExportacion: String!
        $fechaVigenciaCredito: String!
        $observaciones: String! 
    ) {
    secCreateNit(
        nitCliente: $nitCliente, 
        userName: $userName,
        clienteCredito: $clienteCredito,
        generaFactura: $generaFactura,
        aplicaVacio: $aplicaVacio,
        aplicaLleno: $aplicaLleno,
        nombreCliente: $nombreCliente
        tipoImportacion: $tipoImportacion
        tipoExportacion: $tipoExportacion
        fechaVigenciaCredito: $fechaVigenciaCredito
        observaciones: $observaciones 
    ) {
        message
        data {
        request
        flag
        message
        }
    }
}
`
const secUpdateFcClientInformation = gql`
    mutation MyMutation( 
            $id: Int!
            $userName: String!
            $nitCliente: String! 
            $clienteCredito: String!
            $generaFactura: String! 
            $aplicaVacio: String!
            $aplicaLleno: String!
            $tipoImportacion: String!
            $tipoExportacion: String!
            $fechaVigenciaCredito: String!
            $observaciones: String! 
        ) {
    secUpdateFcClientInformation(
        id: $id, 
        userName: $userName 
        nitCliente: $nitCliente 
        clienteCredito: $clienteCredito
        generaFactura: $generaFactura 
        aplicaVacio: $aplicaVacio,
        aplicaLleno: $aplicaLleno,
        tipoImportacion: $tipoImportacion
        tipoExportacion: $tipoExportacion
        fechaVigenciaCredito: $fechaVigenciaCredito
        observaciones: $observaciones
        ) {
        message
        data {
            flag
            message
            }
        }
    }
`

// =============== ACTIVAR/DESACTIVAR NIT ==================
const secModifyState = gql`
        mutation MyMutation(
            $estado: Int!,
            $id: Int!,
            $userName: String!
            $fechaVigenciaCredito: String!
            $observaciones: String! 
        ) {
    secModifyState(
        estado: $estado, 
        id: $id, 
        userName: $userName
        fechaVigenciaCredito: $fechaVigenciaCredito
        observaciones: $observaciones
        ) {
        message
        data {
            flag
            message
            }
        }
    }
`
// ================== Eliminar nit ==========
const secDeleteNit = gql`
    mutation MyMutation(
        $id: Int! 
        $username: String! 
    ) {
        secDeleteNit(
            id: $id
            username: $username
            ) {
                message
                data {
                flag
                message
            }
        }
    }
`
const querysGraphqlUserVinculation = {
    umTypeUser,
    umRegisterUser,
    umUsersRegistered,
    umDenyUserRequest,
    umAcceptUsersRequest,
    umUsersPermissions,
    umCreateDocument,
    umDocumentByUser,
    umDocument,
    umUserInformation,
    manmMandatesById,
    umRequestResetPassword,
    umResetPassword,
    fmDocuments,
    reqmDocuments,
    umUpdateUserInformation,
    secFetchNit,
    secCreateNit,
    secModifyState,
    secDeleteNit,
    secUpdateFcClientInformation
}

export { querysGraphqlUserVinculation }
